import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link as ChakraLink,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react"
import loadable from "@loadable/component"
import { Link } from "gatsby"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaPhoneAlt } from "react-icons/fa"
import { FiArrowRight } from "react-icons/fi"
import { useDispatch } from "react-redux"
import { useEffectOnce } from "react-use"
import { usePrinticularApi } from "~/hooks/usePrinticularApi"
import { actions } from "~/redux/process"
import { RemoteStoreApi } from "./Stores"

interface Props {
  pageContext: {
    store: RemoteStoreApi
  }
}

const StoreMap = loadable(() => import("./StoreMap"))

const Store: React.FC<Props> = ({ pageContext }) => {
  const { store } = pageContext
  const { t } = useTranslation()
  const api = usePrinticularApi()
  const dispatch = useDispatch()
  const theme = useTheme()

  const [isLoadingStore, setIsLoadingStore] = useState(true)
  const [isStoreActive, setIsStoreActive] = useState(false)

  useEffectOnce(() => {
    const { id: remoteStoreId, remotePrintServiceId } = store

    const fetchStore = async () => {
      const remoteStore = await api.getStoreDetails({
        remotePrintServiceId,
        remoteStoreId,
      })

      setIsStoreActive(!!remoteStore.active)
      setIsLoadingStore(false)
    }

    fetchStore()
  })

  const onOrderFromStore = () => {
    dispatch(
      actions.orderFromStore({
        storeId: store.id,
        storeLat: store.latitude,
        storeLng: store.longitude,
        storePlace: `${store.city}, ${store.address1} ${store.postCode}`,
      })
    )
  }

  return (
    <>
      <Heading as="h1" size="lg" mb={0}>
        {store.name}
      </Heading>

      <Flex flexDir="row" alignItems="center" mb={3} mt={1}>
        <Box
          textDecoration="underline"
          _hover={{
            textDecoration: "none",
          }}
        >
          <Link to="/stores" id="store-link-back">
            {t("templates.layout.stores.Store.OurStores")}
          </Link>
        </Box>
        <Text mx={2} fontSize="xs">
          /
        </Text>
        <Text color="gray.400" id="store-breadcrumb">
          {store.name}
        </Text>
      </Flex>

      <Flex
        mt={10}
        justifyContent="center"
        alignItems="stretch"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Box w={{ base: "100%", md: "50%" }} pr={{ base: 0, md: 5 }}>
          <StoreAddress store={store} />

          <Stack mt={10} spacing={3}>
            <Box>
              <Button
                id="store-order"
                colorScheme="primary"
                color="primary.text"
                onClick={onOrderFromStore}
                isLoading={isLoadingStore}
                isDisabled={isLoadingStore || !isStoreActive}
                rightIcon={
                  isStoreActive ? (
                    <Icon as={FiArrowRight} boxSize={4} mr={2} />
                  ) : undefined
                }
              >
                {isStoreActive
                  ? t("templates.layout.stores.Store.StoreActive")
                  : t("templates.layout.stores.Store.StoreInactive")}
              </Button>
            </Box>

            <Box _hover={{ textDecoration: "none" }} textDecoration="underline">
              <Link
                id="store-return"
                to="/stores"
                color="primary.500"
                style={{
                  color: theme.colors.primary["500"],
                  textDecoration: "inherit",
                }}
              >
                {t("templates.layout.stores.Store.ReturnToStores")}
              </Link>
            </Box>
          </Stack>
        </Box>

        <Box
          w={{ base: "100%", md: "50%" }}
          pl={{ base: 0, md: 5 }}
          mt={{ base: 5, md: "auto" }}
          minH={{ base: 300, md: 400 }}
          h={{ base: 300 }}
        >
          <Box
            h="100%"
            w="100%"
            minH="100%"
            overflow="hidden"
            boxShadow="base"
            borderRadius={4}
          >
            <StoreMap store={store} />
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default Store

interface StoreAddressProps {
  store: RemoteStoreApi
}

const StoreAddress: React.FC<StoreAddressProps> = ({ store }) => {
  return (
    <Stack alignItems="flex-start" spacing={1}>
      <Box>
        <Heading as="h2" size="md" mb={2}>
          Store Address
        </Heading>
      </Box>

      <Text>{store.name}</Text>
      <Text id="store-address">
        {store.address1} {store.address2 && store.address2}
      </Text>
      <Text id="store-city">
        {store.postCode} {store.city}
      </Text>
      <Text id="store-country">{store.country}</Text>

      {store.phone && (
        <ChakraLink
          href={`tel:${store.phone}`}
          color="gray.600"
          textDecoration="underline"
          display="flex"
          alignItems="center"
          _hover={{
            textDecoration: "none",
          }}
        >
          <Icon as={FaPhoneAlt} boxSize={3.5} mr={2} />
          {store.phone}
        </ChakraLink>
      )}
    </Stack>
  )
}
